<template>
  <div class="recharge-record">
    <div class="recharge-record-main">
      <park-header></park-header>
      <go-back-title :title="'充值记录'"></go-back-title>
      <van-tabs v-model:active="active" @change="onTabChange" color="#999999">
        <van-tab title="车辆">
          <van-pull-refresh
            v-model="isLoading"
            success-text="刷新成功"
            @refresh="onRefreshVh"
          >
            <van-list
              v-model:loading="isLoading"
              :finished="vhPageOption.total <= vhPageOption.list.length"
              finished-text="没有更多了"
              @load="onLoadVh"
            >
              <div class="table-wrap">

                <div class="date-time-header">
                  <div @click="showDateTimePicker = true" >{{currentDateTxt}}</div>
                  <div class="down-triangle" @click="showDateTimePicker = true" ></div>
                  <div class="search-input" v-if="[userTypesTxt.park, userTypesTxt.groupCompany].includes(userInfo.userTypeName)">
                    <van-field size="mini"
                               v-model="plateNumberInputVal"
                               label="" placeholder="车牌号"
                               right-icon="search"
                               oninput="value=value.replace(/\s/g, '').toUpperCase()"
                               @click-right-icon="onRefreshVh"
                    />
                  </div>
                </div>


                <div class="table-row" v-for="item in vhPageOption.list">

                  <div class="pay-logo">
                    <img v-if="item.source && item.source % 2 == 1" :src="'./logo/wx-logo.png'" alt="">
                    <img v-else-if="item.source && item.source % 2 == 0" :src="'./logo/zfb-logo.png'" alt="">
                    <span v-else class="inner-park">场</span>
                  </div>
                  <div class="table-mid">
                    <div class="plate-number">{{item.plate_number}}</div>
                    <div>{{item.create_time | getDate}}</div>
                  </div>
                  <div class="table-right">
                    <div class="charge-fee">{{item.charged_fee | filterFee}}</div>
                    <div>{{item | filterEffectiveTime}}</div>
                  </div>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="分组">
          <van-pull-refresh
          v-model="isLoading"
          success-text="刷新成功"
          @refresh="onRefreshGroup"
        >
            <van-list
              v-model:loading="isLoading"
              :finished="groupPageOption.total <= groupPageOption.list.length"
              finished-text="没有更多了"
              @load="onLoadGroup"
            >
              <div class="table-wrap">
                <div class="date-time-header">
                  <div @click="showDateTimePicker = true" >{{currentDateTxt}}</div>
                  <div class="down-triangle" @click="showDateTimePicker = true" ></div>
                  <div class="search-input" v-if="[userTypesTxt.park, userTypesTxt.groupCompany].includes(userInfo.userTypeName)">
                    <van-field size="mini"
                               v-model="groupNameInputVal"
                               label="" placeholder="分组名"
                               right-icon="search"
                               @click-right-icon="onRefreshGroup"
                    />
                  </div>
                </div>

                <div class="table-row" v-for="item in groupPageOption.list">
                  <div class="pay-logo">
                    <img v-if="item.source && item.source % 2 == 1" :src="'./logo/wx-logo.png'" alt="">
                    <img v-else-if="item.source && item.source % 2 == 0" :src="'./logo/zfb-logo.png'" alt="">
                    <span v-else class="inner-park">场</span>
                  </div>
                  <div class="table-mid">
                    <div class="plate-number">{{item.group_name}}</div>
                    <div>{{item.create_time | getDate}}</div>
                  </div>
                  <div class="table-right">
                    <div class="charge-fee">{{item.charged_fee | filterFee}}</div>
                    <div>{{item | filterEffectiveTime}}</div>
                  </div>
                </div>
              </div>
            </van-list>
        </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
    <div v-if="showDateTimePicker" class="bottom-date-time-picker">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="getCurrentDate"
        @cancel="getCurrentDate"
      />
    </div>
  </div>
</template>

<script>
import parkHeader from '../../public/park-header'
import * as common from '../../api/common'
import * as api from '../../api/api'
import * as mqtt from '../../api/wsconnect.js'
import goBackTitle from '../../public/go-back-title'

// import * as util from '../../api/util.js'
import { mapState } from 'vuex'
export default {
  name: 'recharge-record',
  components: {
    parkHeader,
    goBackTitle
  },
  data () {
    return {
      showDateTimePicker: false,
      plateNumberInputVal: '',
      groupNameInputVal: '',
      userTypesTxt: common.userTypesTxt,
      currentDate: new Date(),
      currentDateTxt: common.fmtDate(new Date(), 'yyyy年MM月'),
      formatter: (type, val) => {
        if (type === 'year') {
          return `${val}年`;
        }
        if (type === 'month') {
          return `${val}月`;
        }
        return val;
      },
      minDate: new Date(2018, 0, 1),
      maxDate: new Date(),
      active: 0,
      isLoading: false,
      vhPageOption: {
        total: 0,
        page_no: 1,
        page_size: common.globlePagesize,
        list: []
      },
      groupPageOption: {
        total: 0,
        page_no: 1,
        page_size: common.globlePagesize,
        list: []
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    onRefreshVh () {
      this.vhPageOption.page_no = 1
      this.vhPageOption.total = 0
      this.vhPageOption.list = []
      this.initList().catch(e => console.log(e))
    },
    onLoadVh () {
      this.vhPageOption.page_no++
      this.initList().catch(e => console.log(e))
    },
    onRefreshGroup () {
      this.groupPageOption.page_no = 1
      this.groupPageOption.total = 0
      this.groupPageOption.list = []
      this.initList().catch(e => console.log(e))
    },
    onLoadGroup () {
      this.groupPageOption.page_no++
      this.initList().catch(e => console.log(e))
    },
    async initList() {
      try {
        if (this.userInfo.userTypeName == common.userTypesTxt.owner) {
          if (this.active == 0) {
            if (common.getHistoryPlateNumber().concat(common.getHistoryStorePlate()).length == 0) {
              this.$toast('未查询到数据')
              return
            }
          } else if (this.active == 1) {
            if (common.getHistoryGroupName().length == 0) {
              this.$toast('未查询到数据')
              return
            }
          }
        }
        this.$toast.loading('正在请求中...')
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_charge_list}`;
        await mqtt.subscribe(topic)
        this.isLoading = true
        mqtt.Client.config[mqtt.topics.mq_query_charge_list] = this.getList
        let para = {
          sid: localStorage.getItem('sid'),
        }
        let beginEndInfo = common.getMonthBeginEnd(this.currentDate)
        if (this.active == 0) {
          if (this.userInfo.userTypeName == common.userTypesTxt.owner) {
            para.plate_number_list = common.getHistoryPlateNumber().concat(common.getHistoryStorePlate())
          } else if ([common.userTypesTxt.park, common.userTypesTxt.groupCompany].includes(this.userInfo.userTypeName)) {
            if (this.plateNumberInputVal) para.plate_number_list = [this.plateNumberInputVal]
            else {
              para.plate_number_list = common.getHistoryPlateNumber().concat(common.getHistoryStorePlate())
            }
          }
          para.page_no = this.vhPageOption.page_no
          para.page_size = this.vhPageOption.page_size
          para.charge_type = common.chargeType.rent
          para.begin_time = beginEndInfo.begin_time
          para.end_time = beginEndInfo.end_time
        } else if (this.active == 1) {
          if (this.userInfo.userTypeName == common.userTypesTxt.owner) {
            para.group_name_list = common.getHistoryGroupName()
          } else if ([common.userTypesTxt.park, common.userTypesTxt.groupCompany].includes(this.userInfo.userTypeName)) {
            if (this.groupNameInputVal) para.group_name_list = [this.groupNameInputVal]
            else {
              para.group_name_list = common.getHistoryGroupName()
            }
          }
          para.page_no = this.groupPageOption.page_no
          para.page_size = this.groupPageOption.page_size
          para.charge_type = common.chargeType.group
          para.begin_time = beginEndInfo.begin_time
          para.end_time = beginEndInfo.end_time
        }
        let res = await api.axiosRequest(api.urlList.fenglin.query.query_charge_list, para)
        this.$toast.clear()
        this.isLoading = false
        if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
      } catch (e) {
        common.showErrorMessage(this, e)
      }
    },
    async getList (res) {
      if (res.code == common.resCode.OK) {
        if (this.active == 0) {
          this.vhPageOption.list = this.vhPageOption.list.concat(res.list)
          this.vhPageOption.total = res.total || 0
        } else if (this.active == 1) {
          this.groupPageOption.list = this.groupPageOption.list.concat(res.list)
          this.groupPageOption.total = res.total || 0
        }
      } else {
        if (this.active == 0) {
          this.vhPageOption.list = this.vhPageOption.list
        } else if (this.active == 1) {
          this.groupPageOption.list = this.groupPageOption.list
        }
      }
    },
    onTabChange (e) {
      console.log(e)
      if (e == 0) {
        if (!this.vhPageOption.list.length) {
          this.onRefreshVh()
        }
      } else if (e == 1) {
        if (!this.groupPageOption.list.length) {
          this.onRefreshGroup()
        }
      }
    },
    getCurrentDate (e) {
      console.log(e)
      this.showDateTimePicker = false
      if (e) {
        this.currentDateTxt = common.fmtDate(e, 'yyyy年MM月')
        if (this.active == 0) {
          this.vhPageOption.page_no = 1
          this.vhPageOption.total = 0
          this.vhPageOption.list = []
          this.onRefreshVh()
        } else {
          this.groupPageOption.page_no = 1
          this.groupPageOption.total = 0
          this.groupPageOption.list = []
          this.onRefreshGroup()
        }
      }
    }
  },
  filters: {
    getDate (time) {
      if (time) {
        return common.fmtDate(time, 'MM月dd日 hh:mm')
      } else {
        return ''
      }
    },
    filterFee (fee) {
      if (fee <= 0) {
        return 0 //+ '元'
      }
      return (fee / 100).toFixed(2) //+ '元'
    },
    filterEffectiveTime (row) {
      if (!row.effective_date_end || /^29/.test(row.effective_date_end)) {
        return '长期有效'
      } else {
        return common.fmtDate(row.effective_date_start, 'MM-dd') + '~' + common.fmtDate(row.effective_date_end, 'MM-dd')
        // return common.fmtDate(row.effective_date_end, 'yyyy-MM-dd')
      }
    }
  },
  mounted () {
    new mqtt.Client()
    this.initList().catch(e => console.log(e))
  },
  beforeDestroy () {
    let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_charge_list}`
    mqtt.unsubscribe(topic).catch(e => console.log(e))
    delete mqtt.Client.config[mqtt.topics.mq_query_charge_list]
  }
}
</script>

<style scoped lang="scss">
@import "../../theme/index";
.recharge-record {
  display: flex;
  @extend .w-100-h-100;
  /*<!--background-color: $global-theme-bg-blue;;-->*/
  &-main {
    position: relative;
    @extend .content-wrap-gap;
    width: 100%;
    .table-wrap{
      .date-time-header{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem 2rem;
        font-weight: bold;
        background: #F3FAFF;
        & > .down-triangle{
            margin-left: 1rem;
            width: 0;
            height: 0;
            border-left: .6rem solid transparent;
            border-right: .6rem solid transparent;
            border-top: 1.2rem solid gray;
        }
        .search-input{
          margin-left: auto;
        }
      }
      .table-row{
        position: relative;
        display: flex;
        align-items: center;
        height: 8.6rem;
        background-color: white;
        /*.table-cell{*/
          /*box-sizing: border-box;*/
          /*padding: .4rem;*/
          /*flex: 1;*/
        /*}*/
        &:after{
          content: '';
          position: absolute;
          height: .1rem;
          right: 1rem;
          bottom: 0;
          left: 1rem;
          background: #F5F5F5;
        }
        .pay-logo{
          margin: auto 2rem;
          & > img {
            width: 3.8rem;
            height: 3.8rem;
          }
          .inner-park{
            width: 3.8rem;
            height: 3.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $global-theme-blue;
            font-size: $global-font-size-2X-large;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            border: .1rem solid $global-theme-blue;
          }
        }
        .table-mid{
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .plate-number{
            margin-bottom: 1rem;
            font-size: $global-font-size-normal;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: $global-theme-gray-333;
            /*line-height: 22px;*/

          }
          /*background: #0091FF;*/
        }
        .table-right{
          height: 100%;
          margin-left: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          padding-right: 1rem;
          .charge-fee{
            margin-bottom: 1rem;
            font-size: $global-font-size-2X-large;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            /*color: #FF1B1B;*/
            line-height: 30px;
          }
        }
      }

      .van-list{
        min-height: 40rem;
      }
    }
  }
  .bottom-date-time-picker{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20
  }
  /deep/ .van-tab__text{
    font-size: $global-font-size-normal;
  }
  /deep/ .van-tab--active .van-tab__text{
    color: $global-theme-blue !important;
  }
  /deep/ .van-tabs__line{
    background-color: $global-theme-blue !important;
  }
}
</style>
