<template>
  <div class="park-header">
    <div class="logo-img-wrap">
      <img src="../assets/logo.png" alt=""/>
    </div>
    <div class="park-name-wrap">
      <div class="park-name-left-wrap">
        <span @click="showGroupPicker">{{userInfo.parkName}}</span>
        <span class="p-online" v-if="online" @click="getOnlineStatus">在线</span>
        <span class="p-offline" v-else @click="getOnlineStatus">&nbsp;离线</span>
      </div>
      <div>智慧停车系统</div>
      <div class="park-header-account">欢迎{{userInfo.account}}</div>
    </div>
    <div class="park-type">
      <div v-if="userInfo && userInfo.userTypeName == userTypesTxt.groupCompany"
           @click="showGroupPicker">
        <div class="park-type-header" >
          <!--<img :src="'./menu_img/user-sh.png'" alt="">-->
          <!--<van-icon name="wap-nav" />-->
          <van-icon name="more-o" />
        </div>
        <div class="user-nick-name-wrap">
          <!--{{ userInfo.userTypeName == '车场' ? '管理员' :  userInfo.userTypeName}}-->
          车场切换
        </div>
      </div>
    </div>
    <change-park :show.sync="show" @change="getParkVal"></change-park>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as common from '../api/common'
import * as api from '../api/api'
import changePark from './change-park'
export default {
  name: 'park-header',
  components: {
    changePark
  },
  data () {
    return {
      show: false,
      userTypesTxt: common.userTypesTxt
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      online: state => state.online
    })
  },
  methods: {
    async getOnlineStatus () {
      try {
        let para = {
          ...api.getPublicPara('v2'),
          sid: localStorage.getItem('sid')
        }
        let { code } = await api.axiosRequests(api.urlList.fenglin.park.get_online_status, para)
        if (code === common.resCode.OK) {
          this.$store.commit('commitOnline', true)
        } else {
          this.$store.commit('commitOnline', false)
        }
      } catch (e) {
        throw e
      }
    },
    showGroupPicker () {
      console.log(this.show)
      if (this.userInfo.userTypeName == this.userTypesTxt.groupCompany) {
        this.show = true
      }
    },
    getParkVal () {
      this.getOnlineStatus()
    }
  },
  mounted () {
    // this.$store.dispatch('activeUserInfo')
    let userInfo = common.getQueryUserInfo.apply(this)
    console.log('head-user-info', userInfo)
    if (!userInfo || !userInfo.account) {
      this.$router.replace('/login')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../theme/index";
.park-header{
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 1rem 2rem;
  display: flex;
  /*<!--@extend .w-100-h-100;-->*/
  background-color: $global-bg-color;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .logo-img-wrap{
    flex-shrink: 0;
    width: 5.71rem;
    height: 7.2rem;
    & > img {
      width: 100%;
      height: 100%;
    }
    /*background-color: blue;*/
  }
  .park-name-wrap{
    margin-left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    font-weight: bold;
    font-size: $global-font-size-large;
    color: $global-theme-blue;
    white-space: nowrap;
    .park-name-left-wrap{
      display: flex;
      align-items: center;
    }
    .park-header-account{
      margin-top: .4rem;
      font-weight: normal;
      font-size: $global-font-size-normal;
      /*<!--color: $global-theme-gray;-->*/
      color: black;
    }
  }
  .park-type {
    width: 3.2rem;
    height: 4.8rem;
    margin-top: .4rem;
    margin-left: auto;
    text-align: center;
    /*<!--border: $global-color solid .2rem;-->*/
    /*-webkit-border-radius: 50%;*/
    /*-moz-border-radius: 50%;*/
    /*border-radius: 50%;*/
    .park-type-header{
      width: 3.2rem;
      height: 3.2rem;
      /*-webkit-border-radius: 50%;*/
      /*-moz-border-radius: 50%;*/
      /*border-radius: 50%;*/
      display: flex;
      justify-content: center;
      align-items: center;
      /*background: rgba(123, 123, 123, 0.1);;*/
    }
    .user-nick-name-wrap {
      display: flex;
      /*margin: 0 auto;*/
      /*padding: 0;*/
      /*width: 100%;*/
      /*height: 100%;*/
      /*color: $global-theme-blue;*/
      font-size: $global-font-size-mini;
      justify-content: center;
      align-items: center;

      white-space: nowrap;
      letter-spacing: 0;
    }
  }
  .p-online{
    margin: 0 .1rem;
    font-size: $global-font-size-mini;
    color: green;
  }
  .p-offline{
    margin: 0 .1rem;
    font-size: $global-font-size-mini;
    color: red;
  }
}
</style>
